* {
  font-family: 'Montserrat';
}
h1 {
text-align: center;
font-family: 'Montserrat';
font-weight: 500;
margin: 0 0 20px 0;
color: #43352C;
}
p {
text-align: left;
font: normal normal normal 15px/17px Montserrat;
letter-spacing: 2px;
color: #53453C;
opacity: 1;
}
button {
color: white;
padding: 13px 40px;
margin: 5px;
font-size: 14px;
border: 0;
cursor: pointer;
transition: all 0.2s ease;
box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
background: #F9811B 0% 0% no-repeat padding-box;
border-radius: 27px;
opacity: 1;
text-align: left;
font: normal normal bold 15px/17px Montserrat;
letter-spacing: 2.5px;
}
button:hover {
opacity: 0.8;
}
textarea{
display: block;
padding: 8px 15px 8px 10px;
font-size: 12px;
border-radius: 8px;
border: 1px solid #ccc;
margin-top: 10px;
width: 90%;
resize: none;
}
select{
display: block;
padding: 8px 15px 8px 10px;
font-size: 12px;
border-radius: 8px;
border: 1px solid #ccc;
margin-top: 10px;
width: 93%;
}
input, .select-box {
padding: 8px 15px 8px 10px;
font-size: 12px;
border-radius: 8px;
border: 1px solid #ccc;
margin-top: 10px;
}
.input-group, .select-box {
/* margin: 1em; */
margin: 15px 1px;
width: 93%;
}
.input-group label, .column label{
/* display: block; */
text-align: left;
margin: .3em .4em;
font: normal normal normal 15px/17px Montserrat;
letter-spacing: 2px;
color: #53453C;
opacity: 1;
}
.column {
float: left;
width: 49%;
/* padding: 5px; */
margin: 13px 1px;
}
.row:after {
content: "";
display: table;
clear: both;
}
.input-group input, .column input {
width: 90%;
padding: 8px 10px;
border-radius: 5px;
border: 1px solid #dddddd;
font-size: 13px;
}
.logo-pos {
    align-items: center;
    justify-content: center;
    /* width: 100%; */
    display: flex;
    margin-bottom: 20px;
    margin-top: -10px;
}
.out {
display: flex;
justify-content: center;
align-items: center;
background: #fff;
height: 100vh;
margin: 0;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
}
.select-currency {
cursor: pointer;
}
.content {
margin: 15px auto;
padding: 50px;
border: 1px solid #cccccc;
background: #fff;
border-radius: 3px;
width: 80%;
}
.centre{
align-items: center;
justify-content: center;
width: 100%;
display:flex;
margin-top: 20px;
}
.checkboxes{
margin: 10px 2px;
}
.checkboxes label {
display: block;
padding-right: 10px;
padding-left: 22px;
text-indent: -22px;
text-align: left;
font: normal normal normal 13px/15px Montserrat;
letter-spacing: 2px;
color: #53453C;
opacity: 1;
line-height: 18px;
}
.checkboxes input {
vertical-align: middle;
}
.checkboxes label span {
vertical-align: middle;
}
.caption{
text-align: left;
font: normal normal normal 13px/15px Montserrat;
letter-spacing: 2px;
color: #707070;
opacity: 0.71;
line-height: 18px;
}
.right {
  text-align: right;
  font-size: 12px;
  opacity: 0.7;
}
::placeholder{
text-align: left;
font: normal normal normal 11px/13px Montserrat;
letter-spacing: 1.7px;
color: #CCCAC9;
opacity: 1;
/* padding-top: 3%; */
}
/* auto wrap place holder */
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
white-space:pre-line;
position:relative;
/* top:-7px; */
}
::-moz-placeholder { /* Firefox 19+ */
white-space:pre-line;
position:relative;
top:-7px;
}
:-ms-input-placeholder { /* IE 10+ */
white-space:pre-line;
position:relative;
top:-7px;
}
:-moz-placeholder { /* Firefox 18- */
white-space:pre-line;
position:relative;
top:-7px;
}
@media only screen and (max-width: 480px) {
  body {
      font-size: 10px;
  }
  p { 
      font-size: 10px;
  }
  select {
      padding: 4px 10px 4px 8px;
      font-size: 10px;
  }
  option {
      font-size: 10px;
  }
  button {
      font-size: 10px;
      padding: 8px 20px;
      border-radius: 14px;
      letter-spacing: 2px;
  }
  .content {
      padding: 20px 30px;
      width: 80%;
  }
  .right {
      font-size: 9px;
  }
  .input-group label, .column label{
      font-size: 10px;
  }
  .input-group input, .column input {
      /* width: 90%; */
      padding: 3px 5px;
      font-size: 9px;
  }
  ::placeholder {
      font-size: 7px;
      letter-spacing: 0.5px;
  }
  .column {
      margin: 5px 1px;
  }
  .checkboxes label {
      font-size: 10px;
  }
  .caption {
      font-size: 9px;
      line-height: 10px;
  }
  .centre {
      margin-top: 0;
  }
  .submit-button {
    color: #FFF;
  }
}