@import url(https://fonts.googleapis.com/css?family=Montserrat);
* {
    font-family: Montserrat;
    overflow-x: hidden;
}
@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
h2 {
    text-align: center;
    position: absolute;
    top: 48%;
    left: 0;
    right: 0;
    margin-top: -36px;
    color: #53453C;
    font-weight: 200;
    animation: fadeInAnimation ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    line-height: 1.8;
  }
/* a:link {
    color: orange;
    background-color: transparent;
    text-decoration: none;
}
a:visited {
    color: orange;
    background-color: transparent;
    text-decoration: none;
}
a:hover {
    color: orange;
    background-color: transparent;
    text-decoration: underline;
} */
.container {
  top: 70%;
}
/* .SocialButtonsContainer { */
.social {
  margin: 0px 8px;
  position: absolute;
  top: 73%;
  /* bottom: 10%; */
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
  font-weight: 100;
  color: #53453C;
  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
.thank {
  font-weight: 500;
  font-size: 30px;
}
.centre_pos{
  align-items: center;
  justify-content: center;
  width: 100%;
  display:flex;
  /* margin-top: 20px; */
  position: absolute;
  top: 15%;
  /* animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards; */
}
.social_button {
  display: inline-block; 
  position: relative; 
  width: 60px; 
  height: 60px; 
  margin: 0px 0px; 
  border-radius: 40%;
  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@media (max-width: 550px) {
  h1{
    font-size: 20px;
    line-height: 37px;
    letter-spacing: 1px;
    margin: -20px 20px 20px 20px;
    top:35%;
  }
  h2 {
    font-size: 17px;
  }
  .thank {
    font-size: 20px;
  }
}
@media (max-width: 480px) {
  h1{
    margin-top: -20px;
  }
  .centre-pos {
    position: absolute;
    top: 30%;
  }
  .thank {
    font-size: 17px;
  }
  .social {
    top: 60%;
  }
  .social-button {
    height: 40px;
    width: 40px;
    margin: 5px;
  }
}
@media (max-width: 270px) {
  h1{
    position: block;
    /* margin: 10px; */
    top: 5%;
    margin-top: 0;
  }
}
